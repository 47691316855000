import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { TbArrowBigRight } from "react-icons/tb"

export default function Home({ location }) {
  console.log("loc", location)

  return (
    <Layout pageTitle="Home">
      <section className="reading">
        <StaticImage className="screenshot" objectFit="contain" src="../images/kyoko-taiko.jpg" alt="Kyoko Ogoda" />

        <article>
          <p>Kyoko Ogoda hails from Shizuoka, Japan. She started playing the piano with her mother Tomoko Ogoda at the age of 3. She began studying her major instrument the marimba at the age of 9.</p>

          <p>
            She attended the University of Toronto from 2003-2006 where she studied percussion with Robin Engelman and Russell Hartenberger of Nexus, and marimba with Beverly Johnston. During her time in Toronto, Kyoko was a member of the Japanese taiko drumming group{" "}
            <a href="https://nagatashachu.com/">Nagata Shachu</a> and toured Canada, the US, and Italy with them.
          </p>
          <p>
            She’s been teaching Japanese Taiko drumming since 2013, building a community in Kingston, and collaborating with different types of musicians and cultures as well as giving workshops. <a href="https://www.facebook.com/kingsdontaiko/">Kings DON Taiko</a> , a local group she founded, often
            performs locally including regularly at the Kingston Multicultural Arts Festival.
          </p>
        </article>
        <article>
          <h2>Contact</h2>
          <p>Please get in touch if you're interested in finding out more.</p>
          <Link to="contact" className="button">
            Contact
            <TbArrowBigRight />
          </Link>
        </article>
      </section>
    </Layout>
  )
}
